<template>
    <div class="container">
        <headerWX class="headerWX"></headerWX>
        <div class="content">

            <!-- 头部图片 -->
            <div class="headerImg">
                <img style="width: 100%;" src="@/assets/imgs/SignIn/topImg.png" alt="">
                <div class="hintImg">
                    <img src="@/assets/imgs/SignIn/topHint.png" alt="">
                    <div class="tit">
                        <p>{{ data.curYear }}年/{{ data.weekday }}</p>
                        <p>{{ data.todayDate }}日</p>
                        <p>本月累计签到：{{ data.signDay }}天</p>
                    </div>
                </div>
            </div>

            <!-- 签到内容 -->
            <div class="hintContent">
                <div class="hintleft">
                    <div v-for="(item, i) in data.signInDateList" :key="item.dateNo"
                        :class="{ filter: item.status == 1 }" class="item" @click="sign_in_pop_up(item)">
                        <img style="width: 100%;" src="@/assets/imgs/SignIn/hintBg.png" alt="">
                        <div class="item_t">
                            <p class="day">第{{ item.dateNo }}天</p>
                            <div class="img">
                                <el-tooltip class="box-item" effect="light" :content="item.prizeName"
                                    placement="top-start">
                                    <img style="width: 50%;max-height: 100px;" :src="item.prizeImg">
                                </el-tooltip>

                            </div>
                            <p class="text">X {{ item.prizeNum }}</p>
                        </div>
                        <div class="ishint_ok" v-if="item.status == 1">
                            <img style="width: 100%;" src="@/assets/imgs/SignIn/sign_in_ok.png" alt="">
                        </div>
                        <div class="ishint_repair" v-else-if="item.status == 2">
                            <img style="width: 100%;" src="@/assets/imgs/SignIn/sign_in_repair.png" alt="">
                        </div>
                        <div class="ishint_vip" v-if="item.tips !== null">
                            <img style="width: 100%;" src="@/assets/imgs/SignIn/sign_in_vip.png" alt="">
                            <div class="vip_text">VIP3双倍</div>
                        </div>
                    </div>
                </div>
                <div class="hintright">
                    <img src="@/assets/imgs/SignIn/all_award_Bg.png" alt="">
                    <div class="all_item">
                        <div class="tit">全勤奖 ({{ data.signDay }}/{{ data.totalDay }}天)</div>
                        <div class="item" v-for="(item, i) in data.fullPrizeList" :key="i"
                            :class="{ filter: item.status == 2 }">
                            <img src="@/assets/imgs/SignIn/all_award_item.png" alt="">
                            <div class="item_c">
                                <div class="img">
                                    <el-tooltip class="box-item" effect="light" :content="item.prizeName"
                                        placement="top-start">
                                        <img style="width: 50%;max-height: 100px;" :src="item.prizeImg" alt="">
                                    </el-tooltip>

                                </div>
                                <p class="text">X 1 </p>
                            </div>
                            <div class="ishint_vip" v-if="i == 1 || i == 2">
                                <img style="width: 100%;" src="@/assets/imgs/SignIn/sign_in_vip.png" alt="">
                                <div v-if="i == 1" class="vip_text">VIP5激活</div>
                                <div v-if="i == 2" class="vip_text">VIP10激活</div>
                            </div>
                            <div class="ishint_ok" v-if="item.status == 2">
                                <img style="width: 100%;" src="@/assets/imgs/SignIn/sign_in_ok.png" alt="">
                            </div>
                        </div>
                        <!-- 领取按钮 -->
                        <div class="btn">
                            <img v-if="data.fullPrizeStatus == 0" style="filter: grayscale(100%);"
                                src="@/assets/imgs/SignIn/all_award_btn.png" alt="">
                            <img v-else-if="data.fullPrizeStatus == 1" @click="monthEnding"
                                src="@/assets/imgs/SignIn/all_award_btn.png" alt="">
                            <img v-else-if="data.fullPrizeStatus == 2" style="filter: grayscale(100%);"
                                src="@/assets/imgs/SignIn/all_award_btn.png" alt="">
                        </div>
                    </div>
                </div>
            </div>

            <!-- 签到规则 -->
            <div class="rule">
                <div class="tit"><img src="@/assets/imgs/SignIn/rule_tit.png" alt=""></div>
                <p> 1）每日签到请于当日24点前完成，逾期可进行补签，每月前两次补签消耗30绑定白金币，之后每次消耗30白金币。</p>
                <p>2）全勤奖点击领取后请登录游戏打开收件箱查收。</p>
                <p>3）全勤奖领取完成后，如果VIP等级提升相应奖励等级，可以再次领取差额部分奖励。</p>
                <p>4）全勤奖请于当月领取，逾期不补。</p>
            </div>
        </div>
        <footerWX class="footerWX"></footerWX>

        <!-- 二次确认弹窗 -->
        <div id="mask" v-if="ispopUp">
            <div style="
            position: fixed;
            top: 13%;
            left: 35%;
            border-radius: 10px;
            width: 30vw;
            height: 70vh;
            min-width: 600px;
            padding: 5px;
            background: linear-gradient(#d7b7ff, #f9f8ff);
            z-index: 9999;
          ">
                <popUp @ispopUp="TzpopUp" propShow="4" :DBF_goShop="DBF_goShop"></popUp>
            </div>
        </div>

    </div>
</template>

<script>
import headerWX from "@/components/Header";
import footerWX from "@/components/Footer";
import axios from "axios";
import Cookies from "js-cookie";
import popUp from "@/components/pop_up";

export default {
    components: {
        headerWX,
        footerWX,
        popUp,
    },
    data() {
        return {
            // 总数据
            data: {},

            ispopUp: false,
            DBF_goShop: "",

            // 按钮时间戳
            btnReceiveTime: "",
        };
    },
    created() {
        this.init();
        window.scrollTo(0, 0);
    },
    mounted() { },
    methods: {

        go(item) {
            if (item.btnUrl.indexOf("http") != -1) {
                window.open(item.btnUrl, "_blank");
            } else {
                this.$router.push({
                    path: item.btnUrl,
                });
            }
        },


        sign_in(dateNo, typenum) {
            axios({
                method: "post",
                url: "/signIn/doSignin",
                headers: {
                    token: Cookies.get("wx_token"),
                },
                data: {
                    dateNo: dateNo,
                    userId: Cookies.get("wx_userId"), // userId
                    platForm: "web",
                    signInType: typenum  // 正常签到  1   补签 2
                },
            })
                .then((res) => {
                    this.btnReceiveTime = new Date().getTime();

                    if (res.data.state == 200) {
                        this.$message.success(res.data.message);
                        this.init();
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
                .catch((err) => {
                    this.btnReceiveTime = new Date().getTime();

                    this.$message.error(err.message);
                });
        },

        // 签到
        sign_in_pop_up(item) {

            const firstStatusOne = this.data.signInDateList.find(item => item.status === 2);

            // console.log(firstStatusOne, '第一次出现', new Date().getDate());
            // console.log(item, '签到');

            if (new Date().getTime() - this.btnReceiveTime < 6000) {
                this.$message.warning("请勿多次点击~~~");
                console.log(item);

            } else if (firstStatusOne && (item.status == 2 || item.status == 0)) {  // 存在补签行为
                if (firstStatusOne.dateNo == item.dateNo) { //补签行为正确

                    // 判断是否有免费次数
                    if (this.data.freeSigninNums == 1) {
                        this.sign_in(item.dateNo, 2)
                    } else {
                        // 补签
                        this.DBF_goShop = this.data.totalSupplyNums == 2 ? {
                            goodsId: item.dateNo,
                            goodsName: '30白金币'
                        } : {
                            goodsId: item.dateNo,
                            goodsName: '30绑定白金币'
                        }

                        // 弹窗打开
                        this.ispopUp = true;
                    }

                } else {   // 补签行为 不正确
                    this.$message.warning(`请先签到 ${firstStatusOne.dateNo} 号，必须按照顺序签到!`);
                }

            } else if (firstStatusOne == undefined && item.status == 0) {
                if (new Date().getDate() == item.dateNo) { //签到日期正确
                    // 正常签到  1
                    this.sign_in(item.dateNo, 1)

                } else { //签到日期不正确
                    this.$message.warning("日期未到，无法签到");
                }
                // 补签
                // this.DBF_goShop = this.data.totalSupplyNums == 2 ? {
                //     goodsId: item.dateNo,
                //     goodsName: '30白金币'
                // } : {
                //     goodsId: item.dateNo,
                //     goodsName: '30绑定白金币'
                // }

                // 弹窗打开
                // this.ispopUp = true;
            }

        },

        // 关闭蒙层
        TzpopUp(msg) {
            // console.log(msg, "msg_pop");

            if (msg.data == true && msg.type == 3) {
                // console.log('关闭弹窗', this.DBF_goShop);
                // 补签  2
                this.sign_in(this.DBF_goShop.goodsId, 2)


            } else if (msg.data == false && msg.type == 3) {
                this.$message.info('已取消')
            }
            this.ispopUp = false;
        },

        // 获取当前年月
        getCurrentYearMonth() {
            const date = new Date();
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            return `${year}${month}`;
        },
        //全勤奖励领取
        monthEnding() {
            // console.log('全勤',this.getCurrentYearMonth());
            axios({
                method: "post",
                url: "/signIn/getfullPrize",
                headers: {
                    token: Cookies.get("wx_token"),
                },
                data: {
                    month: this.getCurrentYearMonth(),
                    userId: Cookies.get("wx_userId"), // userId
                    platForm: "web",
                },
            })
                .then((res) => {
                    if (res.data.state == 200) {
                        // console.log(res.data);
                        this.$message.success(res.data.message);

                        this.init();
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
                .catch((err) => {
                    this.$message.error(err.message);
                });

        },

        // 初始化
        init() {

            // 签到列表 签到活动管理
            axios({
                method: "post",
                url: "/signIn/getSignInList",
                headers: {
                    token: Cookies.get("wx_token"),
                },
                data: {
                    activityName: "signin",
                    userId: Cookies.get("wx_userId"), // userId
                    platForm: "web",
                },
            })
                .then((res) => {
                    if (res.data.state === 200) {
                        // console.log(res.data);
                        this.data = res.data.data;
                    } else {
                        this.$message.error(res.data.message);
                    }
                })
                .catch((err) => {
                    this.$message.error(err.message);
                });
        },
    },
};
</script>
<style lang="less" scoped>
.container {
    min-width: 1200px;
    overflow: hidden;
    background: linear-gradient(180deg, #150C29 0%, #7D47AA 100%);

    .content {
        margin: 0 auto;
        max-width: 2000px;
        width: 100%;
        position: relative;


        // 灰置
        .filter {
            filter: grayscale(100%);
        }

        // 头部图片
        .headerImg {
            width: 70%;
            margin: auto;
            position: relative;

            .hintImg {
                position: absolute;
                bottom: 15px;
                left: 21%;

                .tit {
                    position: absolute;
                    top: 45%;
                    width: 100%;
                    text-align: center;

                    p {
                        font-size: 18px;
                        padding: 5px 0;
                        color: #ff8000;
                    }
                }
            }

        }

        // 签到内容
        .hintContent {
            width: 70%;
            margin: auto;
            display: flex;

            .hintleft {
                flex: 8;
                // background-color: #d80cb6;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                padding-top: 20px;

                .item {
                    width: 19%;
                    margin-right: 10px;
                    margin-bottom: 10px;
                    position: relative;

                    .item_t {
                        width: 100%;
                        height: 86%;
                        transform: translateY(-50%);
                        position: absolute;
                        top: 50%;

                        .day {
                            height: 18%;
                            line-height: 30px;
                            text-align: center;
                            color: #ff8000;
                            font-size: 18px;
                            font-weight: 600;
                        }

                        .img {
                            height: 64%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                        .text {
                            height: 18%;
                            line-height: 30px;
                            text-align: center;
                            // color: #ff8000;
                            font-size: 16px;
                            font-weight: 600;
                        }
                    }

                    .ishint_ok {
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        width: 100px;
                    }

                    .ishint_repair {
                        position: absolute;
                        right: 0;
                        bottom: 0px;
                        width: 60px;
                    }

                    .ishint_vip {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 80px;

                        .vip_text {
                            position: absolute;
                            top: 19%;
                            left: 4px;
                            font-size: 12px;
                        }
                    }
                }

                // height: 100px;
            }

            .hintright {
                flex: 2;
                height: 100%;
                position: relative;

                .all_item {
                    position: absolute;
                    top: 6%;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 70%;
                    height: 90%;

                    .tit {
                        padding: 15px 0;
                        text-align: center;
                        font-size: 24px;
                    }

                    .item {
                        text-align: center;
                        position: relative;
                        margin-bottom: 15px;

                        .item_c {
                            position: absolute;
                            width: 100%;
                            height: 87%;
                            top: 10px;

                            .img {
                                height: 75%;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }

                            .text {
                                height: 25%;
                                line-height: 30px;
                                text-align: center;
                                // color: #ff8000;
                                font-size: 16px;
                                font-weight: 600;
                            }
                        }

                        .ishint_vip {
                            position: absolute;
                            left: 7px;
                            top: 0;
                            width: 80px;

                            .vip_text {
                                position: absolute;
                                top: 19%;
                                left: 13px;
                                font-size: 12px;
                            }
                        }

                        .ishint_ok {
                            position: absolute;
                            right: 7px;
                            bottom: 0;
                            width: 100px;
                        }
                    }

                    .btn {
                        margin-top: 20px;
                        text-align: center;
                    }
                }
            }
        }

        // 签到规则
        .rule {
            width: 70%;
            margin: 20px auto 50px auto;
            padding: 20px;
            background: rgba(255, 255, 255, 0.4);
            border-radius: 10px 10px 10px 10px;
            border: 1px solid #FFFFFF;

            .tit {
                width: 100%;
                text-align: center;
                padding-top: 20px;
            }

            p {
                font-size: 24px;
                line-height: 60px;
                text-indent: 1rem;
                font-weight: 900;
            }
        }
    }

    // 确认弹窗
    #mask {
        width: 100vw;
        height: 100vh;
        // opacity: 0.6;
        background-color: rgba(0, 0, 0, 0.8);
        bottom: 0;
        left: 0;
        position: fixed;
        z-index: 999;
    }

    /deep/.footerWX {
        position: relative;
    }

    /deep/.headerWX {
        background-color: #3022c5;

        .navWrap {
            color: #fff !important;

            .navLi {
                font-weight: 400 !important;
            }


        }

    }

}
</style>