<template>
    <div class="pubMask">
        <!-- <el-dialog :visible.sync="isShowPask" :width="propShowSwitch == 4 ? '850px' : '600px'">
            <div class="maskMain"> -->
        <img class="closed" @click="closeMask" src="@/assets/imgs/public/close.png" alt="">
        <div style="height: 100%;" v-if="propShow == 1">
            <div
                style="height: 20%;line-height: 140px;font-weight: 800; font-size: 24px; font-family: 'Courier New', Courier, monospace; text-align: center;">
                恭喜您注册成功</div>
            <div class="content">
                <img src="@/assets/imgs/menu/wx.png" alt="">
            </div>
            <div
                style="height: 20%;line-height: 140px;font-weight: 800; font-size: 24px; font-family: 'Courier New', Courier, monospace; text-align: center;">
                扫码加入官方社群更多福利等着您！</div>
            <div style="text-align: center;">
                <button
                    style="padding: 10px 20px; background-color: #00BFFF; color: #fff; border-radius: 5px; border: none; cursor: pointer;"
                    @click="goguanwang">前往官网</button>
            </div>
        </div>

        <div v-if="propShow == 2" style="font-size: 24px !important;">
            <h1 style="margin-top: 50px;font-weight: bold;text-align: center;font-size: 30px">注册信息确认</h1>
            <br>
            <p style="color: red;text-align: center;">请确认您的注册资料，尤其注册后不可修改的部分!</p>
            <br>
            <br>
            <div style="width: 80%;margin-left: 20%;font-size: 21px">
                <p> 游戏账号：{{ registerForm.username == '' ? '暂无' : registerForm.username }}
                    &nbsp;(不可修改)</p>
                <br>
                <p>游戏昵称：{{ registerForm.usernick == '' ? '暂无' : registerForm.usernick }}</p>
                <br>

                <p>真实姓名：{{ registerForm.realname == '' ? '暂无' : registerForm.realname }} &nbsp;(不可修改)</p>
                <br>

                <p>身份证号：{{ registerForm.idcard == '' ? '暂无' : registerForm.idcard }} &nbsp;(不可修改)</p>
                <br>

                <p>游戏性别：{{ registerForm.sex == 1 ? '男' : '女' }} &nbsp;(不可修改)</p>
                <br>

                <p>绑定手机：{{ registerForm.mobile == '' ? '暂无' : registerForm.mobile }}</p>
                <br>
            </div>
            <div style="display: flex;justify-content: center;margin-top: 50px;">
                <button
                    style="border: none;width: 100px;height: 40px;margin-right: 40px;background:repeating-linear-gradient(to left,#FCA2F2, #EB97F9,  #FFA3F0 );color: #fff;border-radius: 25px;cursor: pointer;"
                    @click="hintSucceed">确认</button>
                <button
                    style="border: none;margin-left: 40px;width: 100px;height: 40px;background-color: #FFF;color: #FCA2F2;border-radius: 25px;cursor: pointer;"
                    @click="hintFail">取消</button>
            </div>

        </div>
        <!-- <div v-if="forgetWhichFlag == 1 || forgetWhichFlag == 2" class="title">jjjj</div> -->
        <!-- </div> -->
        <!-- </el-dialog> -->

         <!-- 兑换弹窗 -->
        <div v-if="propShow == 3" style="height: 100%;">

            <div style="height: 70%;display:flex;flex-direction: column;align-items: center;justify-content: center;">
                <p style="margin: 50px 0;font-size:2rem;">您确认要兑换"<span style="color: red;font-size:2.3rem;">{{
                        DBF_goShop.goodsName }}</span>"吗</p>
                <p style="font-size: 1.5rem;">本次兑换操作不可逆</p>
            </div>

            <div style="display: flex;justify-content: center; margin-top: 50px;">
                <button
                    style="border: none;font-size: 24px;width: 150px;height: 50px;margin-right: 40px;background:repeating-linear-gradient(to left,#FCA2F2, #EB97F9,  #FFA3F0 );color: #fff;border-radius: 25px;cursor: pointer;"
                    @click="duanwuYes">确认</button>
                <button
                    style="border: none;font-size: 24px;margin-left: 40px;width: 150px;height: 50px;background-color: #FFF;color: #FCA2F2;border-radius: 25px;cursor: pointer;"
                    @click="duanwuNo">取消</button>
            </div>
        </div>
        <!-- 补签 -->
        <div v-if="propShow == 4" style="height: 100%;">

            <div style="height: 70%;display:flex;flex-direction: column;align-items: center;justify-content: center;">
                <p style="margin: 50px 0;font-size:2rem;">本次补签消耗"<span style="color: red;font-size:2.3rem;">{{
                        DBF_goShop.goodsName }}</span>"</p>
                <p style="font-size: 1.5rem;">本次补签操作不可逆</p>
            </div>

            <div style="display: flex;justify-content: center; margin-top: 50px;">
                <button
                    style="border: none;font-size: 24px;width: 150px;height: 50px;margin-right: 40px;background:repeating-linear-gradient(to left,#FCA2F2, #EB97F9,  #FFA3F0 );color: #fff;border-radius: 25px;cursor: pointer;"
                    @click="duanwuYes">确认</button>
                <button
                    style="border: none;font-size: 24px;margin-left: 40px;width: 150px;height: 50px;background-color: #FFF;color: #FCA2F2;border-radius: 25px;cursor: pointer;"
                    @click="duanwuNo">取消</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'pop_up',
    props: {
        isShowPask: true,
        propShow: 0,
        registerForm: '',
        yuanMobileProp: '',          // 修改手机号时：原手机号
        tipCon: '',               // 文案提示信息
        DBF_goShop: '',           // 端午兑换商品3
    },
    data() {
        return {

        }           // 用户信息
    },
    // created() {
    // },
    mounted() {
        // console.log(this.DBF_goShop, this.propShow,'this.DBF_goShop');
    },
    methods: {
        closeMask() {
            this.$emit('ispopUp', { data: false, type: 1 });
        },
        hintSucceed() {
            this.$emit('ispopUp', { data: true, type: 2 });
        },
        hintFail() {
            this.$emit('ispopUp', { data: false, type: 2 });
        },
        // 
        duanwuYes() {
            this.$emit('ispopUp', { data: true, type: 3 });
        },
        duanwuNo() {
            this.$emit('ispopUp', { data: false, type: 3 });
        },
        goguanwang() {
            // window.location.href = "https://wx.qtthtech.com/#/";
            this.$router.push({ path: '/' });
        }
    }
};
</script>

<style lang="less" scoped>
.pubMask {
    height: calc(100% - 10px);
    width: calc(100% - 10px);
    margin-top: 5px;
    margin-left: 5px;
    background: linear-gradient(#F6F1FF, #D7C5FF);
    border-radius: 10px;
    position: absolute;
    top: 0;
    left: 0;

    .closed {
        position: absolute;
        width: 7vw;
        height: 7vw;
        top: -3vw;
        right: -3vw;
    }

    .content {
        height: 45%;
        width: 50vw;
        display: table-cell;

        vertical-align: middle;
        text-align: center;

        img {
            width: 50%;
        }
    }
}
</style>